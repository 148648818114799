import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    modeloSelecionado: null,
    turnoSelecionado: null,

    turnos: null,
    modelos: null,
    statusGerais: null,
    fluxoStatus: null,
    escolaAnexo: null,

    modelosParaImportar: null,
    descritoresParaImportar: null,

    descritoresLegendas: null,
    descritores: null,
    descritoresNaoAdicionados: null,
    descritoresModificados: null,

    data_ini: undefined,
    data_fim: undefined,
    data_hover: undefined,
}

const calendarioSlice = createSlice({
    name: 'calendarioReducer',
    initialState: INITIAL_STATE,
    reducers: {
        atualizaDescritoresLegenda: (state, action) => {
            state.descritoresLegendas = action.payload ? { ...action.payload } : null
        },
        atualizaDescritoresCalendario: (state, action) => {
            state.descritores = action.payload ? { ...action.payload } : null
        },
        atualizaDescritoresModificados: (state, action) => {
            state.descritoresModificados = action.payload
        },
        atualizaDescritoresNaoAdicionados: (state, action) => {
            state.descritoresNaoAdicionados = action.payload
        },
        alteraDataIniDescritores: (state, action) => {
            state.data_ini = action.payload
        },
        alteraDataFimDescritores: (state, action) => {
            state.data_fim = action.payload
        },
        alteraDataHover: (state, action) => {
            state.data_hover = action.payload
        },
        alteraModeloSelecionado: (state, action) => {
            state.modeloSelecionado = action.payload
        },
        atualizaModelosCalendario: (state, action) => {
            state.modelos = action.payload ? { ...action.payload } : null;
        },
        atualizaModelosParaImportar: (state, action) => {
            state.modelosParaImportar = action.payload ? { ...action.payload } : null;
        },
        atualizaTurnos: (state, action) => {
            state.turnos = action.payload ? { ...action.payload } : null;
        },
        atualizaDescritoresParaImportar: (state, action) => {
            state.descritoresParaImportar = action.payload ? { ...action.payload } : null
        },
        alteraTurnoSelecionado: (state, action) => {
            state.turnoSelecionado = action.payload
        },
        atualizaFluxoStatus: (state, action) => {
            state.fluxoStatus = action.payload ? { ...action.payload } : null;
        },
        atualizaStatusGerais: (state, action) => {
            state.statusGerais = action.payload ? { ...action.payload } : null;
        },
        atualizaStatusCalendarioSelecionado: (state, action) => {
            let nova_lista_modelos = state.modelos ? [...state.modelos.dados] : [];
            nova_lista_modelos.forEach(modelo => {
                if (modelo.id + "" === action.payload.calendario_id + "") {
                    modelo.status_atual_id = action.payload.status_id
                }
            })
            state.modelos = { ...state.modelos, dados: nova_lista_modelos }
        },
        atualizaListaEscolaAnexo: (state, action) => {
            state.escolaAnexo = action.payload ? { ...action.payload } : null;
        }
    },
})

export const {
    alteraDataFimDescritores,
    alteraDataHover,
    alteraDataIniDescritores,
    alteraModeloSelecionado,
    alteraTurnoSelecionado,
    atualizaDescritoresCalendario,
    atualizaDescritoresLegenda,
    atualizaDescritoresModificados,
    atualizaDescritoresNaoAdicionados,
    atualizaDescritoresParaImportar,
    atualizaFluxoStatus,
    atualizaModelosCalendario,
    atualizaModelosParaImportar,
    atualizaStatusCalendarioSelecionado,
    atualizaStatusGerais,
    atualizaTurnos,
    atualizaListaEscolaAnexo
} = calendarioSlice.actions
export default calendarioSlice.reducer
