import CalendarioAPI from "./api/CalendariosAPI";
import Service from "../../api/Service";
import Utils from "../../utils/Uitls";
import {
  alteraModeloSelecionado,
  atualizaDescritoresCalendario,
  atualizaDescritoresModificados,
  atualizaDescritoresNaoAdicionados,
} from "../calendario/reducer/calendario";
import toastr from "toastr";
import moment from "moment";
import Dados from "../../models/Dados";
import {
  TIPOS_DESCRITORES,
  TIPOS_DESCRITORES_BIMESTRE,
  TIPOS_DESCRITORES_SABADO,
  TIPOS_DESCRITORES_SEMESTRE,
  TIPOS_DESCRITORES_TRIMESTRE,
} from "../../global/constants";
import ModeloTrimestreServices from "./components/modeloTrimestre/ModeloTrimestreServices";
import { retornaBloqueioCalendario } from "./CalendarioHelper";
import { TIPO_ACAO_DESCRITOR } from "./helper";

export default class CalendarioServices {
  static buscaDescritoresLegenda(dispatch, params) {
    Service.factoryRequest(dispatch, CalendarioAPI.buscaDescritoresLegenda, true, params);
  }

  static buscaDescritoresCalendario(dispatch, params) {
    Service.factoryRequest(dispatch, CalendarioAPI.buscaDescritoresCalendario, true, params);
  }

  static buscaModelosCalendario(dispatch, params) {
    dispatch(alteraModeloSelecionado(null));
    Service.factoryRequest(dispatch, CalendarioAPI.buscaModelosCalendario, true, params);
  }

  static buscaModelosParaImportar(dispatch, params) {
    Service.factoryRequest(dispatch, CalendarioAPI.buscaModelosParaImportar, true, params);
  }

  static buscaDescritoresParaImportar(dispatch, params) {
    Service.factoryRequest(dispatch, CalendarioAPI.buscaDescritoresParaImportar, true, params);
  }

  static buscaTurnosCalendario(dispatch, params) {
    Service.factoryRequest(dispatch, CalendarioAPI.buscaTurnosCalendario, true, params);
  }

  static buscaStatusGerais(dispatch, params) {
    Service.factoryRequest(dispatch, CalendarioAPI.buscaStatusGerais, true, params);
  }

  static buscaEscolaAnexo(dispatch, params) {
    Service.factoryRequest(dispatch, CalendarioAPI.buscaEscolaAnexo, true, params);
  }

  static buscaFluxoStatus(dispatch) {
    Service.factoryRequest(dispatch, CalendarioAPI.buscaFluxoStatus, true);
  }
  static buscaQuantidadeMinimaDiasLetivos(dispatch, params, callback) {
    Service.factoryRequest(dispatch, CalendarioAPI.buscaQuantidadeMinimaDiasLetivos, true, params, callback);
  }

  static importarModelo(dispatch, params, onLoading, onSuccess) {
    const callback = (res) => {
      onLoading(false);
      if (res.success) {
        onSuccess();
        CalendarioServices.buscaModelosCalendario(dispatch, {
          ano: params.ano,
          last: true,
        });
        toastr.success("Calendário importado com sucesso");
      }
    };

    onLoading(true);
    Service.factoryRequest(dispatch, CalendarioAPI.importarModelo, true, params, callback);
  }

  static caadastrarNovoModelo(dispatch, params, onLoading, onSuccess, notifica = true) {
    const callback = (res) => {
      onLoading(false);
      if (res.success) {
        onSuccess();
        if (notifica) {
          CalendarioServices.buscaModelosCalendario(dispatch, {
            ano: params.ano,
            last: true,
          });
          toastr.success("Descritor alterado com sucesso");
        }
      }
    };
    onLoading(true);
    Service.factoryRequest(dispatch, CalendarioAPI.cadastraNovoModelo, true, params, callback);
  }

  static editarDescritor(dispatch, params, descritoresLista, onLoading, onSuccess, modelo_trimestre = false) {
    const onResponse = async (response) => {
      onLoading(false);
      if (response.success) {
        onSuccess();

        let descritores = [...descritoresLista];
        let index = descritores.findIndex((desc) => desc.id + "" === response.response.id+ "");

        descritores[index] = response.response;

        let dados = await CalendarioServices.formataInformacoesDescritores(descritores, modelo_trimestre);
        let obj = new Dados();
        obj.dados = dados;
        dispatch(atualizaDescritoresCalendario(obj));
        toastr.success("Descritor alterado com sucesso");
      } else {
        toastr.warning(response.response.mensage);
      }
    };

    onLoading(true);

    Service.factoryRequest(dispatch, CalendarioAPI.saveAndUpdateDescritor, true, params, onResponse);
  }

  static removerDescritor(dispatch, params, descritoresLista, onLoading, onSuccess, modelo_trimestre = false) {
    const onResponse = async (res) => {
      onLoading(false);
      if (res.success) {
        onSuccess();

        let descritores = [...descritoresLista];
        descritores = descritores.filter((desc) => desc.id !== params.id);

        let dados = await CalendarioServices.formataInformacoesDescritores(descritores, modelo_trimestre);
        let obj = new Dados();
        obj.dados = dados;
        dispatch(atualizaDescritoresCalendario(obj));

        toastr.success("Descritor removido com sucesso");
      } else {
        toastr.warning(res.response);
      }
    };

    onLoading(true);

    Service.factoryRequest(dispatch, CalendarioAPI.removerDescritor, true, params, onResponse);
  }

  static adicionaDescritorAniversario = (dispatch, params, callback) => {
    const response = (res) => {
      if(res.dados){
        callback(true);
      }

      if(res.error){
        toastr.warning(res.msgError);
        callback(false)
      }
    }

    Service.factoryRequest(dispatch, CalendarioAPI.saveAniversario, true, params, response);
  }

  static adicionarDescritor(dispatch, params, descritoresLista, onLoading, onSuccess, modelo_trimestre = false) {
    const onResponse = async (response) => {
      onLoading(false);
      if (response.success) {
        onSuccess();
        let descritores = [...descritoresLista];
        descritores.push(response.response);

        let obj = new Dados();
        let dados = await CalendarioServices.formataInformacoesDescritores(descritores, modelo_trimestre);
        obj.dados = dados;

        dispatch(atualizaDescritoresCalendario(obj));
        toastr.success("Descritor inserido com sucesso");
      } else {
        toastr.warning(response.response.mensage);
      }
    };

    onLoading(true);
    Service.factoryRequest(dispatch, CalendarioAPI.saveAndUpdateDescritor, true, params, onResponse);
  }

  static async formataDescritores(listaDescritores) {
    let descritores_formatados = {};
    return new Promise((resolve, reject) => {
      let aux = 0;
      listaDescritores.forEach((item) => {
        aux++;
        let { descritor } = item;

        let params = {
          id: item.id,
          descritor_id: descritor.id,
          cor: descritor.cor,
          descricao: descritor.descricao,
          tipo_descritor_id: descritor.tipo_descritor_id,
          dia_letivo: descritor.dia_letivo,
          data_alteracao: item.data_alteracao,
          data_unica: descritor.data_unica,
          data_inicio: item.data_inicio,
          data_fim: item.data_fim,
          descritor_rede: descritor.descritor_rede,
          descricao_custom: item.descricao_custom,
          aceita_descricao: descritor.aceita_descricao
        };

        if (descritor.data_unica === 1) {
          let datekey = item.data_inicio;

          if (!descritores_formatados[datekey]) {
            descritores_formatados[`${datekey}`] = [params];
          } else {
            descritores_formatados[`${datekey}`].push(params);
          }
        } else {
          let inicio = CalendarioServices.getDateByDataSTR(item.data_inicio);
          let fim = CalendarioServices.getDateByDataSTR(item.data_fim);

          let periodo_datas = CalendarioServices.calculaEmDiasDiferencaDatas(fim, inicio);

          //incrementa dia a dia
          let data = CalendarioServices.getDateByDataSTR(item.data_inicio);
          for (let index = 0; index <= periodo_datas; index++) {

            //ignora os domingos (exceto quando a flag aceita_domingo do descritor relacionado for 1 e a flag de data única for 0)
            if (data.getDay() !== 0 || (data.getDay() === 0 && item.descritor.aceita_domingo+"" === "1" && item.descritor.data_unica+"" === "0")) {
              let datekey = Utils.formatData2(data);

              if (!descritores_formatados[datekey]) {
                descritores_formatados[`${datekey}`] = [params];
              } else {
                descritores_formatados[`${datekey}`].push(params);
              }
            }
            //if (obj[`${datekey}`] && !CalendarioServices.verificaDescritorUnico(obj[`${datekey}`], descritor.id)) {
            data = data.setDate(data.getDate() + 1);
            data = new Date(data);
          }
        }
      });
      if (aux === listaDescritores.length) {
        resolve(descritores_formatados);
      }
    });
  }

  static calculaEmDiasDiferencaDatas(data_final, data_inicial) {
    let periodo_datas = Math.abs(data_final.getTime() - data_inicial.getTime()) / (1000 * 3600 * 24);
    return periodo_datas;
  }

  static calculaDiferencaMeses(data_final, data_inicial) {
    var diff = moment(data_final).diff(moment(data_inicial));
    var meses = moment.duration(diff).asMonths();
    return Math.ceil(meses);
  }

  static verificaDescritorUnico(descritores, descritor_id) {
    return descritores.some((d) => d.descritor_id === descritor_id);
  }

  static getDateByDataSTR(datestr) {
    let datearr = [];

    if (datestr.includes("-")) {
      datearr = datestr.split("-").reverse();
    }

    if (datestr.includes("/")) {
      datearr = datestr.split("/");
    }
    return new Date(datearr[2], parseInt(datearr[1]) - 1, datearr[0]);
  }

  static async buscaDadosSobreDiasLetivos(listaDescritores) {
    try {
      // guardam os dias letivos e não letivos
      let diasNaoLetivos = {};
      let diasLetivos = {};

      //inicializa variaveis de inicio e fim de cada semestre e bimestres
      let inicio1Semestre = undefined;
      let fim1Semestre = undefined;

      let inicio2Semestre = undefined;
      let fim2Semestre = undefined;

      let inicio1Bimestre = undefined;
      let fim1Bimestre = undefined;

      let inicio2Bimestre = undefined;
      let fim2Bimestre = undefined;

      let inicio3Bimestre = undefined;
      let fim3Bimestre = undefined;

      let inicio4Bimestre = undefined;
      let fim4Bimestre = undefined;

      let descritoresSabadoLetivo = {};

      Object.keys(listaDescritores).forEach((data) => {
        listaDescritores[data].forEach((descritor) => {
          //adiciona datas com dias letivos ou nao letivos
          if (descritor.dia_letivo === 2) {
            diasNaoLetivos[`${data}`] = true;
          } else if (descritor.dia_letivo === 1) {
            diasLetivos[`${data}`] = true;
          }
        });

        listaDescritores[data]
          .filter((d) => d.dia_letivo === 0)
          .forEach((descritor) => {
            let dia = CalendarioServices.getDateByDataSTR(descritor.data_inicio).getDay();
            //verifica se não é sabado ou domingo, e se não está nos dias nao letivos.
            if (![0, 6].includes(dia) && !diasNaoLetivos[`${data}`]) {
              diasLetivos[`${data}`] = true;
            }
          });

        listaDescritores[data].forEach((descritor) => {
          //busca datas de inicio e fim dos semestres
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_SEMESTRE_1) {
            inicio1Semestre = CalendarioServices.getDateByDataSTR(data);
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_SEMESTRE_1) {
            fim1Semestre = CalendarioServices.getDateByDataSTR(data);
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_SEMESTRE_2) {
            inicio2Semestre = CalendarioServices.getDateByDataSTR(data);
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_SEMESTRE_2) {
            fim2Semestre = CalendarioServices.getDateByDataSTR(data);
          }

          //busca datas de inicio e fim dos bimestres
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_BIMESTRE_1) {
            inicio1Bimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_BIMESTRE_1) {
            fim1Bimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_BIMESTRE_2) {
            inicio2Bimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_BIMESTRE_2) {
            fim2Bimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_BIMESTRE_3) {
            inicio3Bimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_BIMESTRE_3) {
            fim3Bimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_BIMESTRE_4) {
            inicio4Bimestre = data;
          }
          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.FIM_BIMESTRE_4) {
            fim4Bimestre = data;
          }

          if (descritor.tipo_descritor_id === TIPOS_DESCRITORES.SABADO_LETIVO) {
            descritoresSabadoLetivo[data] = true;
          }
        });
      });

      let diasLetivosSemestre1 = {};
      let diasLetivosSemestre2 = {};

      let diasLetivosBimestre1 = {};
      let diasLetivosBimestre2 = {};
      let diasLetivosBimestre3 = {};
      let diasLetivosBimestre4 = {};

      if (inicio1Semestre && fim1Semestre) {
        diasLetivosSemestre1 = await CalendarioServices.geraListaDiasLetivosSemestre(
          inicio1Semestre,
          fim1Semestre,
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      if (inicio2Semestre && fim2Semestre) {
        diasLetivosSemestre2 = await CalendarioServices.geraListaDiasLetivosSemestre(
          inicio2Semestre,
          fim2Semestre,
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      if (inicio1Bimestre && fim1Bimestre) {
        // console.log('aqui', inicio1Bimestre, fim1Bimestre, diasNaoLetivos, diasLetivos, descritoresSabadoLetivo)
        diasLetivosBimestre1 =  await CalendarioServices.geraListaDiasLetivosSemestre(
          CalendarioServices.getDateByDataSTR(inicio1Bimestre),
          CalendarioServices.getDateByDataSTR(fim1Bimestre),
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      if (inicio2Bimestre && fim2Bimestre) {
        diasLetivosBimestre2 =  await CalendarioServices.geraListaDiasLetivosSemestre(
          CalendarioServices.getDateByDataSTR(inicio2Bimestre),
          CalendarioServices.getDateByDataSTR(fim2Bimestre),
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      if (inicio3Bimestre && fim3Bimestre) {
        diasLetivosBimestre3 =  await CalendarioServices.geraListaDiasLetivosSemestre(
          CalendarioServices.getDateByDataSTR(inicio3Bimestre),
          CalendarioServices.getDateByDataSTR(fim3Bimestre),
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      if (inicio4Bimestre && fim4Bimestre) {
        diasLetivosBimestre4 =  await CalendarioServices.geraListaDiasLetivosSemestre(
          CalendarioServices.getDateByDataSTR(inicio4Bimestre),
          CalendarioServices.getDateByDataSTR(fim4Bimestre),
          diasNaoLetivos,
          diasLetivos,
          descritoresSabadoLetivo
        );
      }

      return {
        diasLetivos: {
          ...diasLetivosSemestre1,
          ...diasLetivosSemestre2,
        },
        countDiasLetivos1semestre: Object.keys(diasLetivosSemestre1).length,
        countDiasLetivos2semestre: Object.keys(diasLetivosSemestre2).length,
        countDiasLetivos1bimestre: Object.keys(diasLetivosBimestre1).length,
        countDiasLetivos2bimestre: Object.keys(diasLetivosBimestre2).length,
        countDiasLetivos3bimestre: Object.keys(diasLetivosBimestre3).length,
        countDiasLetivos4bimestre: Object.keys(diasLetivosBimestre4).length,
        inicio1Bimestre,
        fim1Bimestre,
        inicio2Bimestre,
        fim2Bimestre,
        inicio3Bimestre,
        fim3Bimestre,
        inicio4Bimestre,
        fim4Bimestre,
      };
    } catch (e) {
      return {};
    }
  }

  static async geraListaDiasLetivosSemestre(
    inicioSemestre,
    fimSemestre,
    diasNaoLetivos,
    diasLetivos,
    descritoresSabadosLetivo
  ) {
    return new Promise((resolve, reject) => {
      try {
        let diasLetivosSemestre = {};
        const loop = (data) => {
          let d = new Date(data);
          let nextData = new Date(d.setDate(d.getDate() + 1));

          let key_data = Utils.formatData2(data);

          //se estiver na lista de dias não letivos ignora
          if (diasNaoLetivos[key_data]) {
            if (verificaFimSemestre(nextData)) {
              resolve(diasLetivosSemestre);
            } else {
              loop(nextData);
            }
            return;
          }

          //se for sabado ou domingo e não tiver nos dias letivos
          if (
            ([0, 6].includes(data.getDay()) && !diasLetivos[key_data]) ||
            (data.getDay() === 6 && !descritoresSabadosLetivo[key_data])
          ) {
            if (verificaFimSemestre(nextData)) {
              resolve(diasLetivosSemestre);
            } else {
              loop(nextData);
            }
            return;
          }

          diasLetivosSemestre[key_data] = {
            descritor_id: null,
            cor: "#008000",
            descricao: "DIA LETIVO",
            tipo_descritor_id: null,
            dia_letivo: 1,
            dia_da_semana: data.getDay(),
          };

          if (verificaFimSemestre(nextData)) {
            resolve(diasLetivosSemestre);
          } else {
            loop(nextData);
          }
        };

        const verificaFimSemestre = (nextData) => {
          return !(nextData.getTime() <= fimSemestre.getTime());
        };

        loop(inicioSemestre);
      } catch (e) {
        resolve({});
      }
    });
  }

  static getDescritoresNaoAdicionados = (descritoresTotais, descritoresCalendario, isRede, modelo, dispatch) => {
    let descritoresNaoAdicionados = [];
    let name_descritor_obrigatorio = "obrigatorio";

    if (isRede) {
      name_descritor_obrigatorio = "obrigatorio_rede";
    }

    let descritores = {};
    descritoresCalendario.forEach((descritor) => {
      descritores[descritor.descritor_id] = true;
    });

    descritoresTotais.forEach((descritor) => {
      if (descritor[name_descritor_obrigatorio] === 1) {
        //caso não tenha adicionar
        if (!descritores[descritor.id]) {
          descritoresNaoAdicionados.push(descritor);
          return;
        }

        //verifica se possui regra, caso possua, ver se passa na quantidade mínima
        if (descritor.regras.length > 0) {
          let possouNaRegra = true;
          descritor.regras.forEach((regra) => {
            let { tipo_descritor_ini, tipo_descritor_fim } = regra.periodo;

            let itensRegra = CalendarioServices.buscaRegrasDescritores(
              descritoresCalendario,
              descritor,
              tipo_descritor_ini,
              tipo_descritor_fim,
              regra,
              modelo
            );
            if (!itensRegra || !itensRegra.passouNaRegra) {
              possouNaRegra = false;
            }
          });

          if (!possouNaRegra) {
            descritoresNaoAdicionados.push(descritor);
          }
          return;
        }
      }
    });

    dispatch(atualizaDescritoresNaoAdicionados(descritoresNaoAdicionados));
  };

  static getDescritoresModificados = (descritoresCalendario, dispatch) => {
    let descritoresUnicos = [];
    let descritoresOrdenados = descritoresCalendario
      .filter((d) => !!d.data_alteracao)
      .sort((b, a) => {
        let array_a = a.data_alteracao.split(" ");
        let array_b = b.data_alteracao.split(" ");

        let minutos_a = array_a[1].split(":");
        let minutos_b = array_b[1].split(":");

        let data_alteracao_a = CalendarioServices.getDateByDataSTR(array_a[0]);
        let data_alteracao_b = CalendarioServices.getDateByDataSTR(array_b[0]);

        data_alteracao_a.setHours(parseInt(minutos_a[0]));
        data_alteracao_a.setMinutes(parseInt(minutos_a[1]));

        data_alteracao_b.setHours(parseInt(minutos_b[0]));
        data_alteracao_b.setMinutes(parseInt(minutos_b[1]));

        let data_time_a = data_alteracao_a.getTime();
        let data_time_b = data_alteracao_b.getTime();

        return data_time_a < data_time_b ? -1 : 1;
      });

    let objUnicos = {};
    descritoresOrdenados.forEach((d) => {
      if (!objUnicos[d.descritor_id]) {
        descritoresUnicos.push(d);
        objUnicos[d.descritor_id] = true;
      }
    });

    let listaDescritoresFormatados = descritoresUnicos
      .map((desc) => ({ ...desc, descricao: desc.descritor.descricao }))
      .splice(0, 10);

    dispatch(atualizaDescritoresModificados(listaDescritoresFormatados));
  };

  static montaMes(ano, mes, dados) {
    return new Promise(async (resolve, reject) => {
      let dia_inicio = new Date(ano, mes, 1);
      let dia_fim = new Date(ano, mes + 1, 1);

      const { descritoresMes, countSabadosLetivos } = await CalendarioServices.filtraDescritoresPorMesAno(
        dados.descritoresLista,
        mes,
        ano
      );
      const countDiasLetivos = await CalendarioServices.countDiasLetivos(dados.diasLetivos, mes, ano);

      let dias_do_mes = [];

      let loop = (date) => {
        //adiciona cada dia montando o mes
        dias_do_mes.push(date);

        let d = new Date(date);
        let nextData = new Date(d.setDate(date.getDate() + 1));

        if (!(nextData.getTime() < dia_fim.getTime())) {
          //adiciona dias correspondente ao mes anterior
          for (let x = 0; x < dia_inicio.getDay(); x++) {
            dias_do_mes.unshift(null);
          }

          //adiciona dias correspondente ao proximo mes
          if (dia_fim.getDay() !== 0) {
            for (let x = 6; x >= dia_fim.getDay(); x--) {
              dias_do_mes.push(null);
            }
          }

          let semana_id = 1;
          let aux = 0;
          let semanas = {};

          dias_do_mes.forEach((dia) => {
            aux++;
            if (semanas[semana_id]) {
              semanas[semana_id].push(dia);
            } else {
              semanas[semana_id] = [dia];
            }

            if (aux === 7) {
              aux = 0;
              semana_id++;
            }
          });
          resolve({
            mes,
            ano,
            semanas,
            countDiasLetivos,
            countSabadosLetivos,
            descritoresMes,
            descritores: dados.descritores,
          });
        } else {
          loop(nextData);
        }
      };

      loop(dia_inicio);
    });
  }

  static async countDiasLetivos(diasLetivos, mes, ano) {
    let count = 0;
    Object.keys(diasLetivos).forEach((key_data) => {
      let data = CalendarioServices.getDateByDataSTR(key_data)
      if (
        data.getMonth() === mes && 
        data.getFullYear() === ano &&
        ![0, 6].includes(diasLetivos[key_data].dia_da_semana)
        ) {
        count++;
      }
    });
    return count;
  }

  static async filtraDescritoresPorMesAno(descritores, mes, ano) {
    let descritoresMes = [];
    let countSabadosLetivos = 0;

    descritores.forEach((descritor) => {
      let data = CalendarioServices.getDateByDataSTR(descritor.data_inicio);
      let data_fim = CalendarioServices.getDateByDataSTR(descritor.data_fim);
      if ((data.getMonth() === mes && data.getFullYear() === ano) || (data_fim.getMonth() === mes && data_fim.getFullYear() === ano)) {
        descritoresMes.push(descritor);
        if (descritor.descritor.tipo_descritor_id === TIPOS_DESCRITORES.SABADO_LETIVO) {
          countSabadosLetivos++;
        }
      }
    });

    return { descritoresMes, countSabadosLetivos };
  }

  static async getDescritoresParaSeletorFormulario(
    descritoresInput,
    descritoresDoCalendario,
    data_ini,
    data_fim,
    isRede
  ) {
    //se nao for rede, nao lista descritores de rede
    if (!isRede) {
      descritoresInput = descritoresInput.filter((desc) => desc.descritor_rede === 0);
    }

    //verifica se existe descritores de dia letivo no intervalo selecionado, e remove descritores existente nesse intervalo
    descritoresInput = await CalendarioServices.filtraDescritoresPorDiaLetivoRemoveDescritoresJaAdicionados(
      descritoresDoCalendario,
      data_ini,
      data_fim,
      descritoresInput
    );

    //busca tipos descritores base para o calendário e retorna se descritores de bimestre é obrigatório
    let { tiposDescritoresBase, bimestreObrigatorio, trimestreObrigatorio } = await CalendarioServices.getListaBaseParaDescritoresInput(
      descritoresInput
    );

    let { descritoresLista } = descritoresDoCalendario;

    //percorre todos os descritores adicionados no calendario criando um objeto pra facilitar a pesquisa abaixo
    let descritoresPorTipo = {};
    descritoresLista.forEach((desc) => {
      descritoresPorTipo[`${desc.descritor.tipo_descritor_id}`] = true;
    });

    //verifica se só uma data foi selecionada
    let data_unica = data_ini.getTime() === data_fim.getTime();

    if (data_unica) {
      //verifica se existe descritorInicio1Semestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.INICIO_SEMESTRE_1]) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_SEMESTRE_1);
      }
      //verifica se existe descritorFim1Semestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.FIM_SEMESTRE_1]) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.FIM_SEMESTRE_1);
      }

      //verifica se existe descritorInicio2Semestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.INICIO_SEMESTRE_2]) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_SEMESTRE_2);
      }

      //verifica se existe descritorFim2Semestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.FIM_SEMESTRE_2]) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.FIM_SEMESTRE_2);
      }

      //verifica se existe decritorInicio1Bimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.INICIO_BIMESTRE_1] && bimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_BIMESTRE_1);
      }

      //verifica se existe descritorFim1Bimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.FIM_BIMESTRE_1] && bimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.FIM_BIMESTRE_1);
      }

      //verifica se existe descritorInicio2Bimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.INICIO_BIMESTRE_2] && bimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_BIMESTRE_2);
      }

      //verifica se existe descritorFim2Bimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.FIM_BIMESTRE_2] && bimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.FIM_BIMESTRE_2);
      }

      //verifica se existe descritorInicio3Bimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.INICIO_BIMESTRE_3] && bimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_BIMESTRE_3);
      }

      //verifica se existe descritorFim3Bimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.FIM_BIMESTRE_3] && bimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.FIM_BIMESTRE_3);
      }

      //verifica se existe descritorInicio4Bimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.INICIO_BIMESTRE_4] && bimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_BIMESTRE_4);
      }

      //verifica se existe descritorFim4Bimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.FIM_BIMESTRE_4] && bimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.FIM_BIMESTRE_4);
      }

      // funções relacionadas ao trimestre
      //verifica se tem o descritor inicio1Trimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.INICIO_TRIMESTRE_1] && trimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_TRIMESTRE_1);
      }

      //verifica se tem o descritor fim1Trimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.FIM_TRIMESTRE_1] && trimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.FIM_TRIMESTRE_1);
      }
      
      //verifica se tem o descritor inicio2Trimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.INICIO_TRIMESTRE_2] && trimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_TRIMESTRE_2);
      }
      
      //verifica se tem o descritor fim2Trimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.FIM_TRIMESTRE_2] && trimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.FIM_TRIMESTRE_2);
      }
      
      //verifica se tem o descritor inicio3Trimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.INICIO_TRIMESTRE_3] && trimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.INICIO_TRIMESTRE_3);
      }

      //verifica se tem o descritor fim3Trimestre
      if (!descritoresPorTipo[TIPOS_DESCRITORES.FIM_TRIMESTRE_3] && trimestreObrigatorio) {
        return descritoresInput.filter((desc) => desc.tipo_descritor_id === TIPOS_DESCRITORES.FIM_TRIMESTRE_3);
      }

      //verifica se a data selecionada é diferente de sábado, caso seja adiciona na lista_base sabado letivo e sabado de reposicao
      if (data_ini.getDay() !== 6) {
        tiposDescritoresBase.push(TIPOS_DESCRITORES.SABADO_LETIVO);
        tiposDescritoresBase.push(TIPOS_DESCRITORES.REPOSICAO_AULA);
      }

      return descritoresInput.filter((desc) => !tiposDescritoresBase.includes(desc.tipo_descritor_id));
    } else {
      if (tiposDescritoresBase.find((a) => !descritoresPorTipo[a])) {
        toastr.warning("Selecione uma única data para inserir proximo descritor.");
        return [];
      } else {
        return descritoresInput.filter((d) => d.data_unica === 0);
      }
    }
  }

  static verificaDescritoresRepetidosNoIntervaloDeDatas(data_ini, data_fim, descritoresCalendarioObj) {
    let periodo_datas = CalendarioServices.calculaEmDiasDiferencaDatas(data_fim, data_ini);
    let data = new Date(data_ini);

    let lista_descritores_existentes = [];
    let dia_letivo = 0;

    for (let index = 0; index <= periodo_datas; index++) {
      let datekey = Utils.formatData2(data);

      let descritoresByData = descritoresCalendarioObj[datekey];

      if (descritoresByData) {
        // eslint-disable-next-line no-loop-func
        descritoresByData.forEach((des) => {
          dia_letivo = des.dia_letivo;
          lista_descritores_existentes.push(des.descritor_id);
        });
      }

      data = data.setDate(data.getDate() + 1);
      data = new Date(data);
    }

    return { lista_descritores_existentes, dia_letivo };
  }

  static async validacaoSubmit(descritor, data_ini, data_fim, descritoresCalendario, type, modelo, modelo_trimestre = false) {
    let descritoresPorTipo = {};
    descritoresCalendario.forEach((desc) => {
      descritoresPorTipo[`${desc.descritor.tipo_descritor_id}`] = desc;
    });

    if (type === TIPO_ACAO_DESCRITOR.EDITAR) {
      let dataPermitida = await CalendarioServices.verificaDataDescritorSabado(descritor, data_ini);
      if (!dataPermitida) {
        toastr.warning("Data não permitida para descritor. Insira um sábado.");
        return false;
      }
    }

    if (type === TIPO_ACAO_DESCRITOR.EXCLUIR) {
      if ([...TIPOS_DESCRITORES_SEMESTRE, ...TIPOS_DESCRITORES_BIMESTRE, ...TIPOS_DESCRITORES_TRIMESTRE].includes(descritor.tipo_descritor_id)) {
        toastr.warning("Alteração deste descritor não é permitida.");
        return false;
      }
    }

    if (type === TIPO_ACAO_DESCRITOR.EDITAR || type === TIPO_ACAO_DESCRITOR.SALVAR) {
      let dentroDoAnoLetivo = await CalendarioServices.verificaSeEstaDentroDoAnoLetivo(
        descritor,
        descritoresPorTipo,
        data_ini
      );

      if (!dentroDoAnoLetivo) {
        toastr.warning("Descritor de dia letivo, deve ser entre início 1 semestre e fim do 2 semestre");
        return false;
      }

      if (data_ini && data_fim) {
        if (descritor.data_unica === 1 && data_ini.getTime() !== data_fim.getTime()) {
          toastr.warning("Não é permitido o cadastro de multiplas datas para este descritor");
          return false;
        }

        if (data_fim.getTime() < data_ini.getTime()) {
          toastr.warning("Data final menor que data inicial");
          return false;
        }
        let data_inicio_calendario = CalendarioServices.getDateByDataSTR(modelo.data_inicio).getTime();
        let data_fim_calendario = CalendarioServices.getDateByDataSTR(modelo.data_termino).getTime();

        let data_inicio_descritor = data_ini.getTime();
        let data_fim_descritor = data_fim.getTime();

        if (
          data_inicio_descritor < data_inicio_calendario ||
          data_inicio_descritor > data_fim_calendario ||
          data_fim_descritor < data_inicio_calendario ||
          data_fim_descritor > data_fim_calendario
        ) {
          toastr.warning("Data do descritor não está dentro do intervalo do calendário");
          return false;
        }

        if (data_ini.getDay() === 0 || data_fim.getDay() === 0) {
          toastr.warning("Não pode adicionar descritores no domingo.");
          return false;
        }

        let { INICIO_SEMESTRE_1, FIM_SEMESTRE_1, INICIO_SEMESTRE_2, FIM_SEMESTRE_2 } = TIPOS_DESCRITORES;
        
        //FIM_1S > INICIO_1S
        if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,INICIO_SEMESTRE_1,FIM_SEMESTRE_1)) {
          toastr.warning("Fim do semestre 1, deve ser após início do semestre 1");
          return false;
        }

        //INICIO_2S > FIM_1S
        if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,FIM_SEMESTRE_1,INICIO_SEMESTRE_2)) {
          toastr.warning("Início do semestre 2, deve ser após fim do semestre 1");
          return false;
        }

        //FIM_2S > INICIO_2S
        if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,INICIO_SEMESTRE_2,FIM_SEMESTRE_2)) {
          toastr.warning("Fim do semestre 2, deve ser após início do semestre 2");
          return false;
        }
        
        if (modelo_trimestre) {
          let { FIM_TRIMESTRE_1, FIM_TRIMESTRE_2, FIM_TRIMESTRE_3, INICIO_TRIMESTRE_1, INICIO_TRIMESTRE_2, INICIO_TRIMESTRE_3 } = TIPOS_DESCRITORES
          
          //FIM_1TRIM > INICIO_1TRIM
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,INICIO_TRIMESTRE_1,FIM_TRIMESTRE_1)) {
            toastr.warning("Fim do trimestre 1, deve ser após início do trimestre 1");
            return false;
          }
          
          //INICIO_2TRIM > FIM_1TRIM
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,FIM_TRIMESTRE_1,INICIO_TRIMESTRE_2)) {
            toastr.warning("Início do trimestre 2, deve ser após fim do trimestre 1");
            return false;
          }

          //FIM_2TRIM > INICIO_2TRIM
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,INICIO_TRIMESTRE_2,FIM_TRIMESTRE_2)) {
            toastr.warning("Fim do trimestre 2, deve ser após início do trimestre 2");
            return false;
          }

          //INICIO_3TRIM > FIM_2TRIM
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,FIM_TRIMESTRE_2,INICIO_TRIMESTRE_3)) {
            toastr.warning("Início do trimestre 3, deve ser após fim do trimestre 2");
            return false;
          }

          //FIM_3TRIM > INICIO_3TRIM
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,INICIO_TRIMESTRE_3,FIM_TRIMESTRE_3)) {
            toastr.warning("Fim do trimestre 3, deve ser após início do trimestre 3");
            return false;
          }

        } else {
          let { FIM_BIMESTRE_1, FIM_BIMESTRE_2, FIM_BIMESTRE_3, FIM_BIMESTRE_4, INICIO_BIMESTRE_1, INICIO_BIMESTRE_2, INICIO_BIMESTRE_3, INICIO_BIMESTRE_4 } = TIPOS_DESCRITORES
          
          //FIM_1B > INICIO_1B
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,INICIO_BIMESTRE_1,FIM_BIMESTRE_1)) {
            toastr.warning("Fim do bimestre 1, deve ser após início do bimestre 1");
            return false;
          }
          
          //INICIO_2B > FIM_1B
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,FIM_BIMESTRE_1,INICIO_BIMESTRE_2)) {
            toastr.warning("Início do bimestre 2, deve ser após fim do bimestre 1");
            return false;
          }

          //FIM_2B > INICIO_2B
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,INICIO_BIMESTRE_2,FIM_BIMESTRE_2)) {
            toastr.warning("Fim do bimestre 2, deve ser após início do bimestre 2");
            return false;
          }

          //INICIO_3B > FIM_2B
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,FIM_BIMESTRE_2,INICIO_BIMESTRE_3)) {
            toastr.warning("Início do bimestre 3, deve ser após fim do bimestre 2");
            return false;
          }

          //FIM_3B > INICIO_3B
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,INICIO_BIMESTRE_3,FIM_BIMESTRE_3)) {
            toastr.warning("Fim do bimestre 3, deve ser após início do bimestre 3");
            return false;
          }

          //INICIO_4B > FIM_3B
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,FIM_BIMESTRE_3,INICIO_BIMESTRE_4)) {
            toastr.warning("Início do bimestre 4, deve ser após fim do bimestre 3");
            return false;
          }

          //FIM_4B > INICIO_4B
          if (!CalendarioServices.validaDataDoDescritorInicial(descritor,data_ini,descritoresPorTipo,INICIO_BIMESTRE_4,FIM_BIMESTRE_4)) {
            toastr.warning("Fim do bimestre 4, deve ser após início do bimestre 4");
            return false;
          }
        }

      } else {
        toastr.warning("Selecione datas de inicio e fim do descritor");
        return false;
      }
    }

    return true;
  }
  //valida a data do descritor de inicio
  //ex: verifica se fim do semestre 1 é uma data posterior do inicio do semestre 1
  static validaDataDoDescritorInicial(
    descritor,
    data_ini,
    descritoresCalendarioObj,
    tipo_descritor_inicial,
    tipo_descritor_final
  ) {
    let { tipo_descritor_id } = descritor;

    if (tipo_descritor_id === tipo_descritor_final) {
      let descritor_inicio = descritoresCalendarioObj[tipo_descritor_inicial];
      if (descritor_inicio) {
        let data = CalendarioServices.getDateByDataSTR(descritor_inicio.data_inicio);
        return !(data_ini.getTime() <= data.getTime());
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  static getDomingos(data_ini, data_fim) {
    let periodo_datas = CalendarioServices.calculaEmDiasDiferencaDatas(data_fim, data_ini);
    let data = new Date(data_ini);

    let domingos = 0;
    for (let index = 0; index <= periodo_datas; index++) {
      if (data.getDay() === 0) {
        domingos++;
      }
      data = data.setDate(data.getDate() + 1);
      data = new Date(data);
    }

    return domingos;
  }

  static alteraSituacaoCalendario(dispatch, params, onLoading, onSuccess) {
    const callback = (res) => {
      onLoading(false);
      if (res.success) {
        onSuccess();
      }
    };
    onLoading(true);
    Service.factoryRequest(dispatch, CalendarioAPI.alteraSituacaoCalendario, true, params, callback);
  }

  static enviaDiasLetivos(dispatch, params, onLoading, onSuccess) {
    const callback = (res) => {
      onLoading(false);
      if (res.success) {
        onSuccess();
      }
    };
    onLoading(true);
    Service.factoryRequest(dispatch, CalendarioAPI.enviaDiasLetivos, true, params, callback);
  }

  static buscaRegrasDescritores(descritoresCalendario, descritorSelecionado, tipo_inicio, tipo_fim, regra, modelo) {
    let dataInicio = undefined;
    let dataFim = undefined;
    let descritores = [];

    if (tipo_inicio === null) {
      dataInicio = CalendarioServices.getDateByDataSTR(modelo.data_inicio);
    }

    if (tipo_fim === null) {
      dataFim = CalendarioServices.getDateByDataSTR(modelo.data_termino);
    }

    descritoresCalendario.forEach((desc) => {
      if (tipo_inicio !== null && desc.descritor.tipo_descritor_id === tipo_inicio) {
        dataInicio = CalendarioServices.getDateByDataSTR(desc.data_inicio);
        return;
      }

      if (tipo_fim !== null && desc.descritor.tipo_descritor_id === tipo_fim) {
        dataFim = CalendarioServices.getDateByDataSTR(desc.data_inicio);
        return;
      }

      if (desc.descritor_id === descritorSelecionado.id) {
        descritores.push(desc);
      }
    });

    if (!dataInicio) {
      return false;
    }
    if (!dataFim) {
      return false;
    }
    if (descritores.length === 0) {
      return false;
    }

    let passouNaRegra = false;

    //verifica se a regra é descritor tipo mensal
    if (regra.periodo_id === 8) {
      let count_meses = CalendarioServices.calculaDiferencaMeses(dataFim, dataInicio);
      let date = new Date(dataInicio);
      let dados = [];

      for (let i = 0; i <= count_meses; i++) {
        let mes = Utils.completaZero(date.getMonth() + 1);
        let ano = date.getFullYear();

        let mes_ano = `${mes}/${ano}`;
        let descritores_do_mes = descritores.filter((d) => d.data_inicio.includes(mes_ano));

        if (descritores_do_mes.length >= regra.quantidade) {
          dados.push(`${mes_ano}`);
        }
        date.setMonth(date.getMonth() + 1);
        date = new Date(date);
      }

      if (dados.length === count_meses) {
        passouNaRegra = true;
      }
    }

    let descritoresRetorno = descritores.filter((desc) => {
      let data = CalendarioServices.getDateByDataSTR(desc.data_inicio);
      return dataInicio <= data && dataFim >= data;
    });

    return {
      passouNaRegra: regra.periodo_id === 8 ? passouNaRegra : descritoresRetorno.length >= regra.quantidade,
      descritores: descritoresRetorno,
    };
  }

  static getNameTurno(turnoSigla) {
    let obj = {
      M: "manha",
      T: "tarde",
      N: "noite",
      I: "integral",
    };

    return obj[turnoSigla];
  }

  static async formataInformacoesDescritores(descritoresByServer, modelo_trimestre = false) {
    let dados = {};
    let descritores = await CalendarioServices.formataDescritores(descritoresByServer);
    let bloqueios = await CalendarioServices.montaBloqueioCalendario(descritoresByServer)
    let descritoresOrdenadosPorData = descritoresByServer.sort((a, b) => {
      let data_inicio_a = CalendarioServices.getDateByDataSTR(a.data_inicio).getTime();
      let data_inicio_b = CalendarioServices.getDateByDataSTR(b.data_inicio).getTime();
      return data_inicio_a < data_inicio_b ? -1 : 1;
    });
    
    if (modelo_trimestre) {
      let {
        diasLetivos,
        countDiasLetivos1semestre,
        countDiasLetivos2semestre,
        countDiasLetivos1Trimestre,
        countDiasLetivos2Trimestre,
        countDiasLetivos3Trimestre,
        inicio1Trimestre,
        inicio2Trimestre,
        inicio3Trimestre,
        fim1Trimestre,
        fim2Trimestre,
        fim3Trimestre,
      } = await ModeloTrimestreServices.buscaDadosSobreDiasLetivosModeloTrimestre(descritores, modelo_trimestre);

      dados["descritoresLista"] = descritoresOrdenadosPorData;
      dados["descritores"] = descritores;
      dados["diasLetivos"] = diasLetivos;
      dados["countDiasLetivos1semestre"] = countDiasLetivos1semestre;
      dados["countDiasLetivos2semestre"] = countDiasLetivos2semestre;
      dados["countDiasLetivos1trimestre"] = countDiasLetivos1Trimestre;
      dados["countDiasLetivos2trimestre"] = countDiasLetivos2Trimestre;
      dados["countDiasLetivos3trimestre"] = countDiasLetivos3Trimestre;
      dados["inicio1Trimestre"] = inicio1Trimestre;
      dados["inicio2Trimestre"] = inicio2Trimestre;
      dados["inicio3Trimestre"] = inicio3Trimestre;
      dados["fim1Trimestre"] = fim1Trimestre;
      dados["fim2Trimestre"] = fim2Trimestre;
      dados["fim3Trimestre"] = fim3Trimestre;
      dados["bloqueios"] = bloqueios;
      
    } else {
      let {
        diasLetivos,
        countDiasLetivos1semestre,
        countDiasLetivos2semestre,
        countDiasLetivos1bimestre,
        countDiasLetivos2bimestre,
        countDiasLetivos3bimestre,
        countDiasLetivos4bimestre,
        inicio1Bimestre,
        inicio2Bimestre,
        inicio3Bimestre,
        inicio4Bimestre,
        fim1Bimestre,
        fim2Bimestre,
        fim3Bimestre,
        fim4Bimestre,
      } = await CalendarioServices.buscaDadosSobreDiasLetivos(descritores, modelo_trimestre);

      dados["descritoresLista"] = descritoresOrdenadosPorData;
      dados["descritores"] = descritores;
      dados["diasLetivos"] = diasLetivos;
      dados["countDiasLetivos1semestre"] = countDiasLetivos1semestre;
      dados["countDiasLetivos2semestre"] = countDiasLetivos2semestre;
      dados["countDiasLetivos1bimestre"] = countDiasLetivos1bimestre;
      dados["countDiasLetivos2bimestre"] = countDiasLetivos2bimestre;
      dados["countDiasLetivos3bimestre"] = countDiasLetivos3bimestre;
      dados["countDiasLetivos4bimestre"] = countDiasLetivos4bimestre;
      dados["inicio1Bimestre"] = inicio1Bimestre;
      dados["inicio2Bimestre"] = inicio2Bimestre;
      dados["inicio3Bimestre"] = inicio3Bimestre;
      dados["inicio4Bimestre"] = inicio4Bimestre;
      dados["fim1Bimestre"] = fim1Bimestre;
      dados["fim2Bimestre"] = fim2Bimestre;
      dados["fim3Bimestre"] = fim3Bimestre;
      dados["fim4Bimestre"] = fim4Bimestre;
      dados["bloqueios"] = bloqueios;
    }

    return dados;
  }

  static async montaLegandas(legendas) {
    return new Promise((resolve, reject) => {
      let arr = [];
      let listaLegendasFinal = [];
      legendas.forEach((l) => {
        arr.push(l);
        if (arr.length === 2) {
          listaLegendasFinal.push([...arr]);
          arr = [];
        }
      });
      resolve(listaLegendasFinal);
    });
  }

  //verifica se esta dentro do inicio do semestre 1 e final do semestre 2
  static verificaSeEstaDentroDoAnoLetivo = (descritor, descritoresPorTipo, data) => {
    return new Promise((resolve) => {
      let isDiaLetivo = descritor.dia_letivo === 1;

      const { INICIO_SEMESTRE_1, FIM_SEMESTRE_2 } = TIPOS_DESCRITORES;

      //se for dia letivo e nao for nenhum descritor de semestre
      if (isDiaLetivo && !TIPOS_DESCRITORES_SEMESTRE.includes(descritor.tipo_descritor_id)) {
        //verifica data de inicio do 1 semestre e fim do 2 semestre
        if (descritoresPorTipo[INICIO_SEMESTRE_1] && descritoresPorTipo[FIM_SEMESTRE_2]) {
          let dataIni1Semestre = CalendarioServices.getDateByDataSTR(descritoresPorTipo[INICIO_SEMESTRE_1].data_inicio);
          let dataFim2Semestre = CalendarioServices.getDateByDataSTR(descritoresPorTipo[FIM_SEMESTRE_2].data_inicio);

          if (dataIni1Semestre.getTime() > data.getTime() || dataFim2Semestre.getTime() < data.getTime()) {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      }

      resolve(true);
    });
  };

  //verifica se o dia é sabado (letivo ou reposicao), e valida se o dia alterado é sabado tbm
  static verificaDataDescritorSabado = (descritor, data) => {
    return new Promise((resolve) => {
      if (TIPOS_DESCRITORES_SABADO.includes(descritor.tipo_descritor_id)) {
        if (data.getDay() !== 6) {
          resolve(false);
        }
      }
      resolve(true);
    });
  };

  static filtraDescritoresPorDiaLetivoRemoveDescritoresJaAdicionados = (
    descritoresDoCalendario,
    data_ini,
    data_fim,
    descritoresInput
  ) => {
    return new Promise((resolve) => {
      let descritoresLista = [...descritoresInput];

      let { descritores } = descritoresDoCalendario;

      //busca os descritores e o tipo(dia_letivo ou não) adicionados no intervalo de tempo selecionado
      let { dia_letivo, lista_descritores_existentes } =
        CalendarioServices.verificaDescritoresRepetidosNoIntervaloDeDatas(data_ini, data_fim, descritores);

      descritoresLista = descritoresLista.filter((d) => !lista_descritores_existentes.includes(d.id));

      //verifica se é dia_letivo: 1 ou 2.
      if (dia_letivo !== 0) {
        //filtra pelo dia_letivo 1 ou 2 + dia_letivo 0;
        descritoresLista = descritoresLista.filter((d) => d.dia_letivo === dia_letivo || d.dia_letivo === 0);
      }

      resolve(descritoresLista);
    });
  };

  static getListaBaseParaDescritoresInput = (descritoresInput) => {
    return new Promise((resolve, reject) => {
      let bimestreObrigatorio = false;
      let trimestreObrigatorio = false;

      descritoresInput.forEach((desc) => {
        //verifica se o descritor de bimestre é obrigatorio
        if (TIPOS_DESCRITORES_BIMESTRE.includes(desc.tipo_descritor_id) && desc.obrigatorio === 1) {
          bimestreObrigatorio = true;
          return;
        }

        // verifica se o descritor de trimestre é obrigatório
        if (TIPOS_DESCRITORES_TRIMESTRE.includes(desc.tipo_descritor_id) && desc.obrigatorio === 1) {
          trimestreObrigatorio = true;
          return;
        }
      });

      //caso descritor de bimestre seja obrigatorio adiciona na listagem base p/ calendario
      let tiposDescritoresBase = [];

      if (trimestreObrigatorio) {
        tiposDescritoresBase = [...TIPOS_DESCRITORES_SEMESTRE, ...TIPOS_DESCRITORES_TRIMESTRE];
      } else if (bimestreObrigatorio) {
        tiposDescritoresBase = [...TIPOS_DESCRITORES_SEMESTRE, ...TIPOS_DESCRITORES_BIMESTRE];
      } else {
        tiposDescritoresBase = [...TIPOS_DESCRITORES_SEMESTRE];
      }

      resolve({ tiposDescritoresBase, bimestreObrigatorio, trimestreObrigatorio });
    });
  };

   static async montaBloqueioCalendario(descritores){
    const  bloqueiosUser =  await retornaBloqueioCalendario()
     const bloqueios = bloqueiosUser ?  bloqueiosUser.reduce((list, current) => {
        const descritor_inicio = descritores.find(desc => desc?.descritor?.tipo?.id === current.desc_inicio);
        const descritor_fim = descritores.find(desc => desc?.descritor?.tipo?.id === current.desc_fim);
        if(descritor_inicio && descritor_fim){
          list.push({
            descritor_inicio: {
              date: CalendarioServices.getDateByDataSTR(descritor_inicio.data_inicio),
              descricao: descritor_inicio.descritor.tipo.descricao
            },
            descritor_fim: {
              date: CalendarioServices.getDateByDataSTR(descritor_fim.data_fim),
              descricao: descritor_fim.descritor.tipo.descricao
            } 
          })
        }
        return list
    }, []) : []
    return bloqueios
  }
}
