import { CORES_MARCADORES_CALENDARIO, DESCRITOR_ANIVERSARIO_ESCOLA, ROLES, TIPOS_DESCRITORES } from "../../global/constants";
import { getUser } from "../../global/service";
import Dados from "../../models/Dados";
import Utils from "../../utils/Uitls";
import CalendarioServices from "./CalendarioService";
import { atualizaDescritoresCalendario } from "./reducer/calendario";

export const isUsuarioSemec = (user) => {
    switch (user.perfil) {
        case ROLES.PERFIL_ESCOLA:
            if (user.relacionado.empresa_id+"" === '121') {
                return true;    
            } 
            return false;
        case ROLES.PERFIL_GESTOR:
            if (user.relacionado.id+"" === '121') {
                return true;
            }
            return false;
        case ROLES.PERFIL_GRE:
            if (user.relacionado.empresa_id+"" === '121') {
                return true;
            }
            return false;
        default:
            return false;
    }
}

export const converterParaDate = (dataString) => {
    const [dia, mes, ano] = dataString.split('/');
    const dataGMT = new Date(Date.UTC(parseInt(ano, 10), parseInt(mes, 10) - 1, parseInt(dia, 10)));
    dataGMT.setUTCDate(parseInt(dia, 10) + 1);
    return dataGMT;
}

export const geraListaFeriadosRelacionadosUsuario = (usuario, listaFeriadosDia) => {
    if (isUsuarioSemec(usuario)) {
        return listaFeriadosDia;            
    } else {
        if (listaFeriadosDia) {
            let feriado_municipal = listaFeriadosDia.find(item => item.municipio+"" !== "");
            if (listaFeriadosDia.length === 1) {
                if (feriado_municipal) {
                    return [];
                }
            }
            return listaFeriadosDia;
        } 
        return listaFeriadosDia;
    }
}

export const geraListaDescritoresFeriadoNaoCadastrados = (user, lista_feriados) => {
    let lista = [];
    let lista_feriados_filtrados = geraListaFeriadosRelacionadosUsuario(user, lista_feriados);
    lista_feriados_filtrados.forEach(item => {
        let obj = {
            data_inicio: item.data,
            data_fim: item.data,
            cor: CORES_MARCADORES_CALENDARIO.FERIADOS,
            descricao: item.nome.toUpperCase(),
            descricao_custom: null,
            descritor: {
                cor: CORES_MARCADORES_CALENDARIO.FERIADOS,
                descricao: item.nome.toUpperCase(),
                aceita_descricao: 0
            },
            tipo_descritor_id: TIPOS_DESCRITORES.FERIADOS
        };
        lista.push(obj);
    })
    return lista;
}

export const verificaDescricaoAlternativa = (descritor, isPDF = false) => {
    if (isPDF && descritor && descritor.descritor && descritor.descritor.aceita_descricao+"" === '1' && descritor.descricao_custom && descritor.descricao_custom !== "") {
        return true;
    }
    if (descritor && descritor.aceita_descricao+"" === '1' && descritor.descricao_custom && descritor.descricao_custom !== "") {
        return true;
    }
    return false;
}

export const verificaUsuarioAcessoModeloTrimestre = (user, anoSelecionado) => {
    if (user?.app_configuracoes?.trimestre && (JSON.parse(user?.app_configuracoes?.trimestre).map(Number).includes(anoSelecionado))) {
        return true;
    }
    return false;
} 

export const retornaBloqueioCalendario = async () => {
    try{
        let user = null
        await getUser(u => user = u)
        return JSON.parse(user.app_configuracoes.bloqueio_calendario.replace(/'/g, '"'));
    }catch(e){
        return null
    }
}

export const getDataFormatadaComAno = (dt, ano) => {
    const dataFormatada = Utils.formatData3(dt);
    let data = dataFormatada.split("-");
    return `${ano}-${data[1]}-${data[2]}`
}

export const validaCadastroDataFundacao = ({data_ini, data_fim, ano_formacao}) => {

    if(!ano_formacao){
        return "Descreva o ano de formação.";
    }

    const anoMaximo = new Date().getFullYear()
    const anoMinimo = 1840
    const ano = parseInt(ano_formacao)

    if(ano_formacao.length !== 4 || ano <  anoMinimo || ano > anoMaximo){
        return "Insira um ano válido";         
    }

    const dt_ini = new Date(data_ini)
    const dt_fim = new Date(data_fim)
    
    if(dt_ini.getTime() !== dt_fim.getTime()){
        return "Aniversário deve ser data única."
    }

    return  null;
}

export const formatDescritorAniversarioParaListagem = ({data_inicio, data_fim}) => {
    return { descritor: DESCRITOR_ANIVERSARIO_ESCOLA, data_inicio, data_fim}
}   

export const atualizaDescritoresAposCadastroAniversario = async ({descritores, data, modelo_trimestre, dispatch}) => {
    const descritores_lista = [...descritores]
    const index = descritores_lista.findIndex(des => des.descritor.id === DESCRITOR_ANIVERSARIO_ESCOLA.id);
    const descritorAniversario = formatDescritorAniversarioParaListagem({data_fim: data, data_inicio: data})

    if(index !== -1){
        descritores_lista[index] = descritorAniversario;
    }else{
        descritores_lista.push(descritorAniversario)
    }

    let dados = await CalendarioServices.formataInformacoesDescritores(descritores_lista, modelo_trimestre);
    dispatch(atualizaDescritoresCalendario(new Dados(dados)));
}

export const convertDateAniversario = ({data, ano}) => {
    if(!data){
        return ''
    };
    let dt =  Utils.formatDataString(data);
    return `${dt.substring(0, dt.length - 4)}${ano}`;
}

export const checkListaVaziaDescritores = (descritores) => {
    if(!descritores){
      return true
    }
    
    return !descritores.find(d => !!d.id);
  }

export const getId = (user) =>{
    const isEscola = user.perfil === ROLES.PERFIL_ESCOLA;
    return isEscola ? user?.escola?.id : user?.relacionado?.id
}