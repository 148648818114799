import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import { MdModeEditOutline } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import { useSelector } from 'react-redux';
import Utils from '../../../utils/Uitls';
import useLongPress from '../hooks/useLongPress';
import { LISTA_FERIADOS } from '../../../global/lista_feriados';
import { TIPOS_DESCRITORES } from '../../../global/constants';
import { converterParaDate, isUsuarioSemec, verificaDescricaoAlternativa } from '../CalendarioHelper';
import { TIPO_ACAO_DESCRITOR } from '../helper';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: 10,
        width: 300,
        border: '1px solid #dadde9',
        borderRadius: 5,
    },
}));

const retornaListaFeriadosUsuarioRelacionado = (usuario, listaFeriadosDia) => {
    if (listaFeriadosDia && !isUsuarioSemec(usuario)) {
        let lista_feriados_sem_feriado_municipal = listaFeriadosDia.filter(item => item.municipio+"" === "");
        return lista_feriados_sem_feriado_municipal;
    }
    return listaFeriadosDia;
}

const TooltipCalendario = ({ marcadores, diaLetivo, action, children, date, permiteAlteracao }) => {

    const { user } = useSelector(state => state.global);
    const onLongPress = () => action(TIPO_ACAO_DESCRITOR.SALVAR, "long")
    const onClick = () => action(TIPO_ACAO_DESCRITOR.SALVAR, "click")
    const feriadosRelacionadosDia = retornaListaFeriadosUsuarioRelacionado(user, LISTA_FERIADOS[Utils.formatData2(date)])

    const longPressEvent = useLongPress(
        onLongPress,
        onClick,
        {
            shouldPreventDefault: true,
            delay: 300,
        });

    const {
        data_ini,
        data_fim,
        data_hover
    } = useSelector(state => state.calendarioLetivo)

    const [isHover, setIsHover] = useState(false)
    const [isSelect, setIsSelect] = useState(false)

    useEffect(() => {

        if (data_ini && data_hover) {
            let dini = data_ini.getTime()
            let datual = date.getTime();
            let dhover = data_hover.getTime();

            if (datual > dini && datual <= dhover) {
                setIsHover(true)
            } else {
                setIsHover(false)
            }
        }
    }, [data_hover, data_ini])

    useEffect(() => {
        if (!data_ini && !data_fim) {
            setIsHover(false)
            setIsSelect(false)
        } else {
            let datual = Utils.formatData2(date)
            let listdate = [];
            if (data_ini) {
                let d = Utils.formatData2(data_ini)
                listdate.push(d)
            }
            if (data_fim) {
                let d = Utils.formatData2(data_fim)
                listdate.push(d)
            }

            setIsSelect(listdate.includes(datual))
        }
    }, [data_ini, data_fim])

    const renderFooter = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
                <Tooltip title="Editar">
                    <IconButton
                        sx={{ width: 'min-content', border: 'solid 1px', color: '#19a1bd', padding: '5px', marginRight: 1 }}
                        onClick={() => action(TIPO_ACAO_DESCRITOR.EDITAR)}>
                        <MdModeEditOutline size={19} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Excluir">
                    <IconButton
                        sx={{ width: 'min-content', border: 'solid 1px', color: '#d63e3e', padding: '5px' }}
                        onClick={() => action(TIPO_ACAO_DESCRITOR.EXCLUIR)}>
                        <IoMdTrash size={19} />
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const renderHeader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <span style={{ fontWeight: 'bold' }} >Descritor(es):</span>
                <span style={{ backgroundColor: '#03bfa550', padding: '0px 2px', borderRadius: 2 }}>{Utils.formatData2(date)}</span>
            </div>
        )

    }

    const renderBackground = () => {
        return <div
            style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                opacity: isSelect || isHover ? 1 : 0,
                backgroundColor: isSelect ? '#1876d260' : isHover ? '#dedede70' : 'transparent',
                borderRadius: 4
            }}
            {...longPressEvent}
        />
    }

    const retornaListaFeriados = (marcadores, feriadosRelacionadosDia, diaCalendario) => {
        let lista = [];
        let lista_feriados = marcadores ? [...marcadores].filter(item => item.tipo_descritor_id === TIPOS_DESCRITORES.FERIADOS) : null;
        let tam_lista_feriados = lista_feriados ? lista_feriados.length : 0;
        let tam_feriados_relacionados_dia = feriadosRelacionadosDia ? feriadosRelacionadosDia.length: 0;

        if (feriadosRelacionadosDia) {
            lista = [...lista, ...feriadosRelacionadosDia];
        }

        if (lista_feriados && lista_feriados.length !== 0) {
            let nova_lista_feriados = []

            lista_feriados.forEach(item => {
                //se o descritor de feriado tiver data unica
                if (item.data_inicio === item.data_fim) {
                    // Se o feriado não estiver sido pre-cadastrado na lista de feriados
                    if (!LISTA_FERIADOS[diaCalendario]) {
                        nova_lista_feriados.push(item); 
                    } else {
                        // se ja tiver feriado pre cadastrado mas a lista de feriados possui mais de um descritor de periodo unico
                        if (nova_lista_feriados.length < (tam_lista_feriados - tam_feriados_relacionados_dia)) {
                            nova_lista_feriados.push(item); 
                        }
                    }
                } else {
                    // Não tem data única
                    for (let index = converterParaDate(item.data_inicio); index <= converterParaDate(item.data_fim); index.setDate(index.getDate() + 1)) {
                        //verificar se tem feriado relacionado e comparar a data, se a data não estiver presente enviar ela para a lista de feriados
                        if (Utils.formatData2(index) === diaCalendario && (nova_lista_feriados.length < (tam_lista_feriados - tam_feriados_relacionados_dia))) {
                            nova_lista_feriados.push({ ...item, data_inicio: Utils.formatData2(index), data_fim: Utils.formatData2(index)}) 
                        }
                    }
                }
            })

            lista = [...lista, ...nova_lista_feriados];
        }

        if (lista && lista.length > 0) {
            return lista.map((desc, key) => <p key={key} style={{ fontSize: 13, marginBottom: 4 }}>{desc.nome ? desc.nome.toUpperCase() : desc.descricao.toUpperCase()}</p>)
        }
        return <></>;
    }

    const retornaListaMarcadores = (marcadores) => {
        if (marcadores) {
            return marcadores.filter(item => item.tipo_descritor_id !== TIPOS_DESCRITORES.FERIADOS).map((desc, key) => <p key={key} style={{ fontSize: 13, marginBottom: 4 }}>{verificaDescricaoAlternativa(desc) ? desc.descricao_custom : desc.descricao}</p>)
        }
        return <></>
    }

    return (
        <>
            {//só mostra tooltip se estiver sem o clique longo
                !isHover &&
                <HtmlTooltip
                    placement="right"
                    title={
                        <React.Fragment>
                            {renderHeader()}
                            {diaLetivo && <p style={{ fontSize: 13, marginBottom: 4 }}>{diaLetivo.descricao}</p>}
                            {retornaListaMarcadores(marcadores)}
                            {retornaListaFeriados(marcadores, feriadosRelacionadosDia, Utils.formatData2(date))}
                            {permiteAlteracao ? renderFooter() : null}
                        </React.Fragment>
                    }>
                    {renderBackground()}
                </HtmlTooltip>
            }
            {
                (!marcadores && !diaLetivo && !feriadosRelacionadosDia) || (isHover) ? renderBackground() : null
            }
            {children}
        </>
    )
}


export default TooltipCalendario;